<template>
    <div class="flex justify-center agreement">
        <div class="pb-2 ag_content sm:pb-4">
            <div class="pt-2 pb-4 text-base font-bold title sm:text-xl">{{ title }}</div>
            <div v-html="content" class="text-left"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            code: "",//协议的code
            content: "",
            title: ""
        }
    },
    created() {
        this.code = this.$route.query.code
        this.aquireAgreement()
    },
    methods: {
        aquireAgreement() {
            this.$request({
                methods: "get",
                url: this.$requestPath.protocolDetail,
                params: {
                    protocolCode: this.code
                }
            }).then(res => {
                console.log("获取协议详情", res)
                if (res.code == 0) {
                    // this.imgObject.push(...res.data)
                    this.content = res.data.protocolContent
                    this.title = res.data.protocolTitle
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '数据获取失败' })
                }
            }).catch(error => {
                console.log("数据请求失败，请稍后再试", error)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.agreement {
    min-height: var(--min-height);
}
@media screen and (max-width: 640px) {
    .ag_content {
        width: 100%;
    }
}
@media screen and (min-width: 640px) {
    .ag_content {
        width: 50rem;
    }
}
</style>